import React from 'react';
import { motion } from 'framer-motion';
import styles from "./about.module.scss";
import { pageClassNames } from "../../utils/constants";
import { Container } from "react-bootstrap";
import { scrollToClass } from '../../helpers/scrollToPageClassName';
import NsanzimfuraBtn from '../../components/AppButton/AppButton';
import AppInViewAnimation from '../../components/AppInViewAnimation';
import LottieBg from '../../components/LottieBg';
import lottie1 from '../projects/lottie1.json';



export default function About() {
  const year = new Date().getFullYear();
  const experienceYears = Number(year) - 2020;

  return (
    <div id={styles.about} className="about">
      <LottieBg lottie={lottie1} styles={{ top: "-300px", transform: `rotate(230deg)` }} />
      <Container className="body">
        <div className="about-text" >
          <motion.h2
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="title title-about"
          >I am a Full stack developer
          </motion.h2>
          <AppInViewAnimation>
            <p className="sub-title sub-title-about">
              Using modern technologies, I build fluid and responsive backend and front-end user experiences.
              My passion for web development and UI/UX design stems from my relentless pursuit of knowledge, my creative mindset, and my desire to positively impact society. I have honed my skills through various projects and roles, such as enhancing web interfaces.
            </p>
          </AppInViewAnimation>
          <AppInViewAnimation delay={0.1}>
            <div className="goals">
              <div className="goalbox vision">
                <div className="goalbox-title goalbox-title-about">My How</div>
                <div className="description-about">
                  Always eager to learn new things, take on challenges, and collaborate with others to create digital experiences that will leave a mark.
                </div>
                <div className="about-Nsanzimfura" >
                  <AppInViewAnimation delay={0.3}>
                    <NsanzimfuraBtn
                      onClick={() => {
                        scrollToClass(pageClassNames.projects)
                      }}
                    >
                      Projects{" "}
                      <img
                        src="./images/nav-arrow-right.svg"
                        className="icon"
                        alt="Nsanzimfura Greater than"
                      />
                    </NsanzimfuraBtn>
                  </AppInViewAnimation>
                </div>
              </div>
              <div className="goalbox mission">
                <div className="goalbox-title mision-title-about">Experience</div>
                <AppInViewAnimation delay={0.3}>
                  <h2 className="years">
                    {experienceYears}+
                  </h2>
                </AppInViewAnimation>
              </div>
            </div>
          </AppInViewAnimation>
        </div>
      </Container>
    </div>
  );
}
