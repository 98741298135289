export const iconsData = [
    {
        id: 1,
        name: "GitHub",
        url: "https://github.com/nsanzimfura-eric",
        icon: "./images/GitHub2.png",
        hoverIcon: "/images/GitHub1.png",

    },
    {
        id: 2,
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/nsanzimfura-eric",
        icon: "/images/LinkedIn2.png",
        hoverIcon: "/images/LinkedIn1.png",

    },
    {
        id: 4,
        name: "Email",
        url: "mailto:erickykress1@gmail.com",
        icon: "/images/email2.png",
        hoverIcon: "/images/email1.png",
    },
]